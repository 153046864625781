import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { useAuthStore } from '@/store/auth';
import File from '@/store/models/File';
import Folder from '@/store/models/Folder';
import logger from './logger';
import { go } from './go';

export enum ModelState {
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
  Persisted = 'persisted',
}

const fetched: string[] = [];

export async function fetchData(
  Model: typeof File | typeof Folder,
  url: string
) {
  // Prevent fetching more than once
  if (fetched.includes(url)) return Promise.resolve();

  const { $firestore } = useNuxtApp();
  const authStore = useAuthStore();
  const user = authStore.getUser;

  fetched.push(url);

  if (user) {
    const collectionRef = collection($firestore, url);
    // const snapshot = await getDocs()
    const orderedQuery = query(collectionRef, orderBy('name'));

    const unsubscribe = onSnapshot(
      orderedQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (
            change.type === 'added' &&
            needsUpdate(
              change.doc.data(),
              useRepo(Model).find(change.doc.data().id)?.$getAttributes()
            )
          ) {
            logger().info(
              'database.ts',
              'fetchData()',
              `${change.doc.data().type} added`
            );
            useRepo(Model).save(change.doc.data());
          }

          if (
            change.type === 'modified' &&
            needsUpdate(
              change.doc.data(),
              useRepo(Model).find(change.doc.data().id)?.$getAttributes()
            )
          ) {
            logger().info(
              'database.ts',
              'fetchData()',
              `${change.doc.data().type} modified`
            );
            //   change.doc.data(),
            //   useRepo(Model).find(change.doc.data().id)?.$getAttributes()
            // );
            useRepo(Model).save(change.doc.data());
          }

          if (
            change.type === 'removed' &&
            needsDelete(
              useRepo(Model).find(change.doc.data().id)?.$getAttributes()
            )
          ) {
            logger().info(
              'database.ts',
              'fetchData()',
              `${change.doc.data().type} removed`
            );
            useRepo(Model).destroy(change.doc.data().id);
          }
        });
      },
      (error) => {
        if (AUTH_ERROR_CODES_MAP[error.code]) {
          logger().error(
            'utils/database.ts',
            'fetchData()',
            AUTH_ERROR_CODES_MAP[error.code],
            error.code
          );

          if (error.code === 'permission-denied') {
            go('/')?.then(() => {
              throw createVisibleError(
                error.name,
                AUTH_ERROR_CODES_MAP[error.code]
              );
            });
          } else {
            throw createVisibleError(
              error.name,
              AUTH_ERROR_CODES_MAP[error.code]
            );
          }
        } else {
          logger().error('utils/database.ts', 'fetchData()', error, error.code);
        }

        // NOTE: delay 1 second to make sure the error shows
        setTimeout(() => {
          go('/');
        }, 1000);
      }
    );

    return unsubscribe;
  } else {
    throw createVisibleError(
      'User not found',
      'Het lijkt erop dat je uitgelogd bent.'
    );
  }
}

export async function createFirestoreRecord(
  model: File | Folder,
  path: string
) {
  assertNonNullish(model, 'createFirestoreRecord');
  const { $firestore, $sentry } = useNuxtApp();
  logger().debug('database.ts', 'createFirestoreRecord()', model);

  try {
    const ref = doc($firestore, path);
    const snap = await getDoc(ref);

    if (needsUpdate(model.$getAttributes(), snap.data())) {
      return setDoc(ref, model.$getAttributes());
    }
  } catch (error) {
    $sentry.captureException(error);
  }

  return null;
}

export async function updateFirestoreRecord(
  model: File | Folder,
  path: string
) {
  assertNonNullish(model, 'updateFirestoreRecord');
  const { $firestore, $sentry } = useNuxtApp();
  logger().debug(
    'database.ts',
    'updateFirestoreRecord()',
    model.$getAttributes(),
    path
  );

  try {
    const ref = doc($firestore, path);
    const snap = await getDoc(ref);

    if (needsUpdate(model.$getAttributes(), snap.data())) {
      return setDoc(ref, model.$getAttributes(), {
        merge: true,
      });
    }
  } catch (error) {
    $sentry.captureException(error);
  }

  return null;
}

export async function deleteFirestoreRecord(
  model: File | Folder,
  path: string
) {
  assertNonNullish(model, 'deleteFirestoreRecord');
  const { $firestore, $sentry } = useNuxtApp();
  logger().debug('database.ts', 'deleteFirestoreRecord()', model);

  try {
    const ref = doc($firestore, path);
    const snap = await getDoc(ref);

    if (snap.exists()) {
      return deleteDoc(doc($firestore, path));
    }
  } catch (error) {
    $sentry.captureException(error);
  }

  return Promise.resolve(null);
}
