import type { StoreModel } from '@/store/models/types';

export function needsUpdate(
  updated: StoreModel,
  current: StoreModel | undefined
) {
  if (current == null) return true;
  // Updated is not updated (shouldn't happen)
  if (updated.updatedAt === '') return false;
  // Current timestamp is newer than updated timestamp
  if (
    new Date(current.updatedAt).getTime() >
    new Date(updated.updatedAt).getTime()
  ) {
    return false;
  }

  for (const property in updated) {
    // Test if (regardless of updatedAt being changed) other properties have
    // also been changed
    if (
      Object.prototype.hasOwnProperty.call(updated, property) &&
      property !== 'updatedAt' &&
      updated[property] !== current[property]
    ) {
      return true;
    }
  }

  // Updated timstamp may still be newer, but since nothing else has changed,
  // ignore these
  return false;
}

export function needsDelete(current: unknown | undefined) {
  if (current != null) return true;

  return false;
}
